import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  private readonly appName = 'Maler Maifarth';

  constructor(
    private readonly title: Title,
    private readonly meta: Meta
  ) { }

  setMetaInformation(title: string, description?: string, image?: string) {
    this.title.setTitle(`${title} - ${this.appName}`);
    if (description) {
      this.meta.updateTag({
        name: 'description',
        content: description
      });
    }
    if (image) {
      this.meta.updateTag({
        name: 'image',
        content: image
      });
    }
  }
}
