import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ContactComponent } from './components/contact/contact.component';
import { HeaderComponent } from './components/header/header.component';
import { HeadingAndTextComponent } from './components/heading-and-text/heading-and-text.component';
import { ImageGridComponent } from './components/image-grid/image-grid.component';
import { ProcessComponent } from './components/process/process.component';
import { TextColumnsComponent } from './components/text-columns/text-columns.component';

@NgModule({
  declarations: [
    HeaderComponent,
    HeadingAndTextComponent,
    TextColumnsComponent,
    ImageGridComponent,
    ContactComponent,
    ProcessComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    HeaderComponent,
    HeadingAndTextComponent,
    TextColumnsComponent,
    ImageGridComponent,
    ContactComponent,
    ProcessComponent
  ]
})
export class SharedModule { }
