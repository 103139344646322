<div class="py-7" [ngClass]="inverted ? 'bg-blue color-white' : ''">
    <div class="container">
        <div class="row" *ngIf="config?.subHeading">
            <div class="col-12">
                <span class="text-uppercase">{{config?.subHeading}}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-6">
                <h1 [ngClass]="inverted ? '' : 'color-blue'" [innerHTML]="config?.h1"></h1>
            </div>
            <div class="col-12 col-lg-6">
                <p *ngFor="let p of config?.text" [innerHTML]="p"></p>
            </div>
        </div>
    </div>
</div>
